<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Confirmar</b-th>
                            <b-th class="th_style signos_vitales text-center">Si</b-th>
                            <b-th class="th_style signos_vitales text-center">No</b-th>
                            <b-th class="th_style signos_vitales text-center">Observaciones</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Concentimiento Inf.-Cirugía firmada</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Concentimiento Inf.-Anestesia firmada</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Laboratorio vigente</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Imagenología</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Preparación física y psicológica</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Hoja de verificación de cirugía segura</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Marcaje correcto</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Ayuno</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Sangre cruzada</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Prevención tromboembólica</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Transferencia interna</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Cirguías previas</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <span class="size-span">Otras enfermedades</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-checkbox size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Medicamentos</b-th>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th colspan="6" class="th_style signos_vitales text-center">Signos vitales</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                            <b-th class="th_style signos_vitales text-center">Temp.</b-th>
                            <b-th class="th_style signos_vitales text-center">F.r.</b-th>
                            <b-th class="th_style signos_vitales text-center">F.c.</b-th>
                            <b-th class="th_style signos_vitales text-center">T.a.</b-th>
                            <b-th class="th_style signos_vitales text-center">SPO2</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Sol. parentales</b-th>
                            <b-th class="th_style signos_vitales text-center">ML.</b-th>
                            <b-th class="th_style signos_vitales text-center">Inicia</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase pt-3"><strong>Reporte de enfermería</strong></p>
                <b-form-textarea autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="5" max-rows="5" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data:() => ({

    })
}
</script>